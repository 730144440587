/*@jsxRuntime classic @jsx React.createElement @jsxFrag React.Fragment*/
import {useMDXComponents as _provideComponents} from "@mdx-js/react";
import React from "react";
function _createMdxContent(props) {
  const _components = Object.assign({
    h1: "h1",
    a: "a",
    div: "div",
    p: "p",
    h2: "h2",
    ul: "ul",
    li: "li",
    strong: "strong"
  }, _provideComponents(), props.components), {MapInfo, LandingPageCta} = _components;
  if (!LandingPageCta) _missingMdxReference("LandingPageCta", true);
  if (!MapInfo) _missingMdxReference("MapInfo", true);
  return React.createElement(React.Fragment, null, React.createElement(_components.h1, {
    className: "c-md-h1",
    id: "misophonia-the-decreased-tolerance-disorder-to-specific-sounds-or-their-associated-triggers",
    style: {
      position: "relative"
    }
  }, React.createElement(_components.a, {
    href: "#misophonia-the-decreased-tolerance-disorder-to-specific-sounds-or-their-associated-triggers",
    "aria-label": "misophonia the decreased tolerance disorder to specific sounds or their associated triggers permalink",
    className: "anchor before"
  }, React.createElement(_components.div, {
    dangerouslySetInnerHTML: {
      __html: "<svg aria-hidden=\"true\" focusable=\"false\" height=\"16\" version=\"1.1\" viewBox=\"0 0 16 16\" width=\"16\"><path fill-rule=\"evenodd\" d=\"M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z\"></path></svg>"
    }
  })), "Misophonia: The decreased tolerance disorder to specific sounds or their associated triggers."), "\n", React.createElement(_components.p, {
    className: "c-md-p"
  }, "Have you ever felt an overwhelming rage or discomfort triggered by the sound of someone chewing gum, tapping their pen, or even breathing heavily? If so, you might be experiencing misophonia, a relatively lesser-known but impactful condition that affects individuals' reactions to specific sounds. Misophonia, often referred to as \"sound sensitivity syndrome,\" can significantly impair one's quality of life, yet many people remain unaware of its existence or its potential connection to ", React.createElement(_components.a, {
    href: "/resources/hearing-loss/",
    className: "c-md-a"
  }, "hearing loss"), "."), "\n", React.createElement(_components.h2, {
    className: "c-md-h2",
    id: "what-is-misophonia",
    style: {
      position: "relative"
    }
  }, React.createElement(_components.a, {
    href: "#what-is-misophonia",
    "aria-label": "what is misophonia permalink",
    className: "anchor before"
  }, React.createElement(_components.div, {
    dangerouslySetInnerHTML: {
      __html: "<svg aria-hidden=\"true\" focusable=\"false\" height=\"16\" version=\"1.1\" viewBox=\"0 0 16 16\" width=\"16\"><path fill-rule=\"evenodd\" d=\"M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z\"></path></svg>"
    }
  })), "What is Misophonia?"), "\n", React.createElement(_components.p, {
    className: "c-md-p"
  }, "Misophonia, a term coined by the American neuroscientists Pawel and Margaret Jastreboff in 2001, literally translates to \"hatred of sound.\" It's characterized by an intense emotional reaction, such as anger, anxiety, or disgust, triggered by certain sounds. These sounds, known as \"trigger sounds,\" can vary from person to person but often include chewing, slurping, typing, or even the sound of someone breathing."), "\n", React.createElement(_components.h2, {
    className: "c-md-h2",
    id: "symptoms-of-misophonia",
    style: {
      position: "relative"
    }
  }, React.createElement(_components.a, {
    href: "#symptoms-of-misophonia",
    "aria-label": "symptoms of misophonia permalink",
    className: "anchor before"
  }, React.createElement(_components.div, {
    dangerouslySetInnerHTML: {
      __html: "<svg aria-hidden=\"true\" focusable=\"false\" height=\"16\" version=\"1.1\" viewBox=\"0 0 16 16\" width=\"16\"><path fill-rule=\"evenodd\" d=\"M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z\"></path></svg>"
    }
  })), "Symptoms of Misophonia"), "\n", React.createElement(_components.p, {
    className: "c-md-p"
  }, "Individuals with misophonia often experience a range of physical and emotional responses when exposed to trigger sounds. These may include:"), "\n", React.createElement(_components.ul, {
    className: "c-md-list"
  }, "\n", React.createElement(_components.li, {
    className: "c-md-li"
  }, React.createElement(_components.strong, null, "Anger or Irritation"), ": A sudden and intense feeling of anger or irritation upon hearing trigger sounds."), "\n", React.createElement(_components.li, {
    className: "c-md-li"
  }, React.createElement(_components.strong, null, "Anxiety"), ": Heightened levels of anxiety or stress in anticipation of encountering trigger sounds."), "\n", React.createElement(_components.li, {
    className: "c-md-li"
  }, React.createElement(_components.strong, null, "Avoidance Behaviors"), ": Going to great lengths to avoid situations or individuals that may produce trigger sounds."), "\n", React.createElement(_components.li, {
    className: "c-md-li"
  }, React.createElement(_components.strong, null, "Physical Symptoms"), ": Some individuals may experience physical reactions such as increased heart rate, sweating, or muscle tension."), "\n"), "\n", React.createElement(_components.h2, {
    className: "c-md-h2",
    id: "common-trigger-sounds",
    style: {
      position: "relative"
    }
  }, React.createElement(_components.a, {
    href: "#common-trigger-sounds",
    "aria-label": "common trigger sounds permalink",
    className: "anchor before"
  }, React.createElement(_components.div, {
    dangerouslySetInnerHTML: {
      __html: "<svg aria-hidden=\"true\" focusable=\"false\" height=\"16\" version=\"1.1\" viewBox=\"0 0 16 16\" width=\"16\"><path fill-rule=\"evenodd\" d=\"M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z\"></path></svg>"
    }
  })), "Common Trigger Sounds"), "\n", React.createElement(_components.p, {
    className: "c-md-p"
  }, "While trigger sounds can vary greatly among individuals, some sounds are commonly reported to evoke strong reactions in people with misophonia. These include:"), "\n", React.createElement(_components.ul, {
    className: "c-md-list"
  }, "\n", React.createElement(_components.li, {
    className: "c-md-li"
  }, React.createElement(_components.strong, null, "Chewing"), ": The sound of someone chewing food, gum, or crunching snacks."), "\n", React.createElement(_components.li, {
    className: "c-md-li"
  }, React.createElement(_components.strong, null, "Sniffling or Snorting"), ": Repetitive nasal sounds like sniffing or snorting."), "\n", React.createElement(_components.li, {
    className: "c-md-li"
  }, React.createElement(_components.strong, null, "Pen Clicking or Typing"), ": The sound of pens clicking, keyboards typing, or mouse clicking."), "\n", React.createElement(_components.li, {
    className: "c-md-li"
  }, React.createElement(_components.strong, null, "Breathing"), ": Certain breathing sounds, especially if they are loud or irregular."), "\n", React.createElement(_components.li, {
    className: "c-md-li"
  }, React.createElement(_components.strong, null, "Repetitive Tapping"), ": Pen tapping, foot tapping, or finger tapping on surfaces."), "\n"), "\n", React.createElement(_components.h2, {
    className: "c-md-h2",
    id: "diagnosing-misophonia",
    style: {
      position: "relative"
    }
  }, React.createElement(_components.a, {
    href: "#diagnosing-misophonia",
    "aria-label": "diagnosing misophonia permalink",
    className: "anchor before"
  }, React.createElement(_components.div, {
    dangerouslySetInnerHTML: {
      __html: "<svg aria-hidden=\"true\" focusable=\"false\" height=\"16\" version=\"1.1\" viewBox=\"0 0 16 16\" width=\"16\"><path fill-rule=\"evenodd\" d=\"M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z\"></path></svg>"
    }
  })), "Diagnosing Misophonia"), "\n", React.createElement(_components.p, {
    className: "c-md-p"
  }, "Currently, there is no standardized test for diagnosing misophonia. Diagnosis is typically made based on a clinical evaluation of symptoms and the individual's reported experiences with trigger sounds. Mental health professionals, such as psychologists or psychiatrists, may use diagnostic criteria to assess whether an individual's reactions align with those commonly associated with misophonia."), "\n", React.createElement(_components.h2, {
    className: "c-md-h2",
    id: "misophonia-and-hearing-loss",
    style: {
      position: "relative"
    }
  }, React.createElement(_components.a, {
    href: "#misophonia-and-hearing-loss",
    "aria-label": "misophonia and hearing loss permalink",
    className: "anchor before"
  }, React.createElement(_components.div, {
    dangerouslySetInnerHTML: {
      __html: "<svg aria-hidden=\"true\" focusable=\"false\" height=\"16\" version=\"1.1\" viewBox=\"0 0 16 16\" width=\"16\"><path fill-rule=\"evenodd\" d=\"M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z\"></path></svg>"
    }
  })), "Misophonia and Hearing Loss"), "\n", React.createElement(_components.p, {
    className: "c-md-p"
  }, "While misophonia and hearing loss are distinct conditions, there may be some overlap in how they affect individuals' auditory experiences. Hearing loss refers to a decrease in one's ability to perceive sounds, often resulting from damage to the auditory system. On the other hand, misophonia is characterized by an emotional and physiological response to specific sounds, despite normal hearing sensitivity."), "\n", React.createElement(_components.p, {
    className: "c-md-p"
  }, "However, there is ", React.createElement("a", {
    href: " https://www.ncbi.nlm.nih.gov/pmc/articles/PMC9294447/",
    target: "_blank"
  }, "emerging research"), " suggesting a potential link between misophonia and auditory processing abnormalities. Some studies have found that individuals with misophonia may exhibit heightened activity in certain brain regions involved in auditory processing, indicating that their brains may perceive and interpret sounds differently than those without misophonia."), "\n", React.createElement(_components.p, {
    className: "c-md-p"
  }, "Additionally, individuals with hearing loss may be more susceptible to developing misophonia due to changes in auditory processing or increased sensitivity to certain sounds. The inability to hear ambient sounds clearly may heighten one's focus on trigger sounds, amplifying their emotional impact."), "\n", React.createElement(_components.p, {
    className: "c-md-p"
  }, "In conclusion, misophonia is a complex and often misunderstood condition characterized by intense emotional reactions to specific sounds. While there is currently no definitive test for diagnosing misophonia, increased awareness and understanding of the condition are crucial for providing support and accommodations to affected individuals. Furthermore, exploring the potential relationship between misophonia and hearing loss may offer valuable insights into both conditions and inform future research and treatment approaches."), "\n", React.createElement(_components.h2, {
    className: "c-md-h2",
    id: "contact-a-hearing-specialist-from-hearcom",
    style: {
      position: "relative"
    }
  }, React.createElement(_components.a, {
    href: "#contact-a-hearing-specialist-from-hearcom",
    "aria-label": "contact a hearing specialist from hearcom permalink",
    className: "anchor before"
  }, React.createElement(_components.div, {
    dangerouslySetInnerHTML: {
      __html: "<svg aria-hidden=\"true\" focusable=\"false\" height=\"16\" version=\"1.1\" viewBox=\"0 0 16 16\" width=\"16\"><path fill-rule=\"evenodd\" d=\"M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z\"></path></svg>"
    }
  })), "Contact a hearing specialist from hear.com"), "\n", React.createElement(_components.p, {
    className: "c-md-p"
  }, "Ready to find relief from misophonia and improve your hearing? At ", React.createElement(_components.a, {
    href: "/",
    className: "c-md-a"
  }, "hear.com"), ", we're here to help. With over 1400 dedicated hearing specialists, we'll guide you on your journey to better hearing. Plus, we partner with the top 2% of audiologists in the United States to ensure you receive expert care. Take the first step towards a happier, healthier hearing experience. Contact us today!"), "\n", React.createElement(MapInfo), "\n", React.createElement(LandingPageCta, {
    copy: "Contact a Hearing Specialist",
    classes: "c-cta--content"
  }));
}
function MDXContent(props = {}) {
  const {wrapper: MDXLayout} = Object.assign({}, _provideComponents(), props.components);
  return MDXLayout ? React.createElement(MDXLayout, props, React.createElement(_createMdxContent, props)) : _createMdxContent(props);
}
export default MDXContent;
function _missingMdxReference(id, component) {
  throw new Error("Expected " + (component ? "component" : "object") + " `" + id + "` to be defined: you likely forgot to import, pass, or provide it.");
}
